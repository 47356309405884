import AddIcon from '@material-ui/icons/AddCircleRounded';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  deleteImage,
  getGetPSU,
  getPutPSU,
  putMinio,
} from '../functions/images';
import compressImage from '../utils/compress';
import { Context } from './Store';

export function TextFormField({
  label = 'Label',
  unit = '',
  value,
  setter,
  id,
  placeholder = label,
  type = 'text',
  form = '',
  required = false,
  readonly = false,
  options = [],
  numFiche = '',
  setError = () => {},
  setInfo = () => {},
  ...rest
}) {
  const [state] = useContext(Context);
  const [psu, setPsu] = useState('');

  useEffect(() => {
    (async () => {
      if (type === 'image' && value !== '') {
        setPsu((await getGetPSU(value, state.token)).url);
      }
    })();
  }, [state.token, type, value]);

  return (
    <div className="flex flex-col text-left px-2 mb-4">
      <label htmlFor={id}>
        {`${label}`}
        {unit && <span className="italic"> ({unit})</span>}
        {required ? <span className="text-viguierJaune"> *</span> : ''}
      </label>
      {type === 'textarea' ? (
        <textarea
          {...rest}
          rows="4"
          id={id}
          value={value}
          onChange={(e) => setter(e.target.value)}
          placeholder={`${placeholder}...`}
          title={placeholder}
          form={form}
          required={required}
          readOnly={readonly}
          className={`w-full border-2 border-black rounded pl-2 py-1 my-1 focus:outline-none focus:ring-2 focus:ring-black focus:border-gray-800 text-black h-full ${
            readonly ? 'bg-gray-400 cursor-not-allowed' : ''
          }`}
        />
      ) : type === 'radio' ? (
        <div
          className="flex w-full items-center justify-evenly h-full"
          onChange={(e) => setter(e.target.value)}
        >
          <span>
            <label className="mr-3" htmlFor={`${id}-true`}>{`${
              options.length > 1 ? options[0]?.label : 'Oui'
            }`}</label>
            <input
              {...rest}
              type="radio"
              id={`${id}-true`}
              value={options.length > 1 ? options[0]?.value : 'true'}
              defaultChecked={
                options.length > 1
                  ? options[0]?.value === value
                  : value === true
              }
              name={id}
            />
          </span>
          <span>
            <label className="mr-3" htmlFor={`${id}-false`}>{`${
              options.length > 1 ? options[1]?.label : 'Non'
            }`}</label>
            <input
              {...rest}
              type="radio"
              id={`${id}-false`}
              value={options.length > 1 ? options[1]?.value : 'false'}
              defaultChecked={
                options.length > 1
                  ? options[1]?.value === value
                  : value === false
              }
              name={id}
            />
          </span>
        </div>
      ) : type === 'image' ? (
        <div>
          <div className="flex justify-center items-center mt-1 w-full border-2 border-black rounded overflow-ellipsis overflow-x-hidden text-sm bg-white">
            {!psu ? (
              <>
                <input
                  {...rest}
                  id={id}
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (!file || !file.type.startsWith('image/')) {
                      return setError('Le fichier doit être une image valide');
                    }
                    setError('');
                    setInfo("Compression de l'image en cours...");
                    try {
                      const compressedFile = await compressImage(file);
                      setInfo("Téléchargement de l'image en cours...");
                      const psu = await getPutPSU(
                        state.token,
                        `${numFiche}_${id}.${file.type.split('/')[1]}`
                      );
                      if (psu.error) {
                        setInfo('');
                        return setError(`Erreur : ${psu.error}`);
                      }
                      const upload = await putMinio(
                        psu.url,
                        compressedFile,
                        file.type
                      );
                      if (upload.error) {
                        setInfo('');
                        return setError(`Erreur : ${upload.error}`);
                      }
                      setInfo('Image téléchargée avec succès !');
                      setter(psu.name);
                    } catch (error) {
                      console.error(error);
                      setInfo('');
                      return setError(`Impossible d'utiliser la photo`);
                    }
                  }}
                  title={placeholder}
                  form={form}
                  required={required}
                  className="w-full pl-2 py-1 focus:outline-none focus:ring-2 focus:ring-black focus:border-gray-800 text-black"
                />
              </>
            ) : (
              <div
                className="text-black cursor-pointer py-1 font-normal"
                onClick={async () => {
                  if (window.confirm("Confirmez la suppression de l'image")) {
                    const deleted = await deleteImage(value, state.token);
                    if (deleted.error) return setError(deleted.error);
                    setInfo("L'image a bien été supprimée");
                    setPsu(null);
                    return setter(null);
                  }
                }}
              >
                Supprimer
                <DeleteIcon />
              </div>
            )}
          </div>
          {psu && value && (
            <img
              src={psu}
              alt="URL manquante ou erronée"
              className="italic font-thin max-h-80 object-contain my-2"
            />
          )}
        </div>
      ) : (
        <input
          {...rest}
          type={type}
          id={id}
          value={value}
          onChange={(e) => setter(e.target.value)}
          placeholder={placeholder}
          title={placeholder}
          form={form}
          required={required}
          readOnly={readonly}
          className={`w-full border-2 border-black rounded pl-2 py-1 my-1 focus:outline-none focus:ring-2 focus:ring-black focus:border-gray-800 text-black ${
            readonly ? 'bg-gray-400 cursor-not-allowed' : ''
          }`}
        />
      )}
    </div>
  );
}

export function Select({
  id,
  label,
  value,
  setter,
  multiple = false,
  locked = 0,
  options = [],
  required = false,
  inline = false,
}) {
  const clicked = (val, isChecked) => {
    if (isChecked) return setter(value.filter((v) => v !== val));
    return setter([...value, val]);
  };
  return (
    <div
      className={`flex flex-col text-left px-2 max-h-40 ${!inline && 'mb-4'}`}
    >
      <label htmlFor={id}>
        {label}
        {required ? <span className="text-viguierJaune"> *</span> : ''}
      </label>
      {multiple ? (
        <ul className="overflow-auto text-black font-normal bg-white border-2 border-black rounded pl-2 py-1 my-1">
          {options.map((o) => {
            const isChecked =
              value.includes(o.value) ||
              value.some((v) => v?.value === o.value);
            return (
              <li key={o.value}>
                {o.value === locked ? (
                  <label className="text-stbSecond">
                    <CheckBoxIcon />
                    {o.label}
                  </label>
                ) : (
                  <label
                    htmlFor={o.value}
                    className="cursor-pointer"
                    onClick={() => clicked(o.value, isChecked)}
                  >
                    {isChecked ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                    {o.label}
                  </label>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <select
          id={id}
          className={`w-full bg-white border-2 border-black rounded ${
            !inline && 'py-1 my-1 pl-2'
          } focus:outline-none focus:ring-2 focus:ring-black focus:border-gray-800 text-black`}
          value={value}
          onChange={(e) => setter(e.target.value || null)}
        >
          {options.map((o) => {
            return (
              <option value={o.value} key={o.value}>
                {o.label}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
}

export function ArrayInput({
  id,
  label,
  values = [],
  setter,
  format = {},
  required = false,
  type = 'text',
  options = [],
  numFiche = '',
  setError = () => {},
  setInfo = () => {},
}) {
  const isSimple = Object.keys(format).length <= 0;
  const [newTextValue, setNewTextValue] = useState(
    isSimple ? [''] : Array(Object.keys(format).length).fill('')
  );
  const [state] = useContext(Context);

  const addRow = async () => {
    if (isSimple) {
      if (newTextValue[0] === '') return null;
      setter([...values, newTextValue[0]]);
      setNewTextValue(['']);
    } else {
      if (newTextValue.some((e) => e === '')) return null;
      const newObj = {};
      Object.keys(format).map((f, i) => (newObj[f] = newTextValue[i]));
      setter([...values, newObj]);
      setNewTextValue(Array(Object.keys(format).length).fill(''));
    }
  };

  const [deletedRows, setDeletedRows] = useState(new Set());

  const deleteRow = (i, event) => {
    event.preventDefault();

    // Afficher la boîte de confirmation
    const isConfirmed = window.confirm("Confirmez la suppression de l'image");

    if (isConfirmed) {
      const imageName = values[i]; // Nom de l'image
      const token = state.token; // Récupération du token depuis localStorage

      // Récupérer les images à supprimer stockées dans le localStorage
      const imagesToDelete =
        JSON.parse(localStorage.getItem('imagesToDelete')) || [];

      // Vérifier si l'image est déjà présente dans le tableau
      const imageExists = imagesToDelete.some(
        (image) => image.imageName === imageName
      );

      if (!imageExists) {
        // Ajouter l'image à la liste des images à supprimer si elle n'existe pas déjà
        imagesToDelete.push({ imageName, token });

        // Sauvegarder le tableau mis à jour dans le localStorage
        localStorage.setItem('imagesToDelete', JSON.stringify(imagesToDelete));
      }

      // Ajouter un message de validation
      setInfo("L'image a bien été supprimée");

      // Création d'une nouvelle liste sans l'élément à supprimer
      const updatedValues = values.filter((value, index) => index !== i);

      // Mettre à jour l'état avec la nouvelle liste après suppression
      setter(updatedValues);
    }
  };

  // Utiliser useEffect pour écouter les événements 'beforeunload' et 'popstate' pour supprimer le localStorage
  const history = useHistory();

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Supprimer 'imagesToDelete' du localStorage dès que l'utilisateur quitte la page
      localStorage.removeItem('imagesToDelete');
    };

    const handlePopState = () => {
      // Supprimer 'imagesToDelete' du localStorage dès que l'utilisateur revient en arrière
      localStorage.removeItem('imagesToDelete');
    };

    const handleNavigation = () => {
      // Supprimer 'imagesToDelete' du localStorage lors de la navigation vers une nouvelle page
      localStorage.removeItem('imagesToDelete');
    };

    // Ajouter les écouteurs d'événements pour 'beforeunload' et 'popstate'
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    // Gérer la navigation via le système de routage (si utilisant React Router)
    const unlisten = history.listen(() => {
      handleNavigation(); // Appeler la suppression lors de la navigation
    });

    // Nettoyage des écouteurs d'événements lorsque le composant est démonté
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
      unlisten(); // Supprimer l'écouteur d'événements de React Router
    };
  }, [history]);

  const updateRow = (val, index, field = '') => {
    if (isSimple) {
      setter(
        values.map((f, i2) => {
          if (i2 === index) return val;
          return f;
        })
      );
    } else {
      setter(
        values.map((f, i2) => {
          if (i2 === index) {
            return { ...f, [field]: val };
          }
          return f;
        })
      );
    }
  };

  const [psus, setPsus] = useState([]);

  useEffect(() => {
    (async () => {
      if (type === 'image' && values.length > 0) {
        const imageUrls = await Promise.all(
          values.map(async (value) => (await getGetPSU(value, state.token)).url)
        );
        setPsus(imageUrls);
      }
    })();
  }, [state.token, type, values]);

  const combinedValues = values.map((value, index) => ({
    imageName: value,
    imageUrl: psus[index],
  }));

  return (
    <div
      className={`flex flex-col text-left px-2 mb-4 ${
        isSimple ? '' : 'sm:col-span-2'
      }`}
    >
      <label htmlFor={id} className="text-lg font-semibold">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div
        className="overflow-y-auto max-h-96 border border-gray-300 rounded-lg shadow-md bg-white"
        style={{ maxHeight: '300px' }} // Limite la hauteur de la section
      >
        <table className="w-full text-sm text-gray-800 bg-white">
          <thead>
            {!isSimple && (
              <tr className="bg-gray-200 text-left text-gray-600">
                {Object.keys(format).map((field, i) => (
                  <th key={i} className="px-4 py-2">
                    {format[field].label}
                  </th>
                ))}
                <th className="px-4 py-2 w-16">Actions</th>
              </tr>
            )}
          </thead>
          <tbody>
            {combinedValues.map(
              (item, i) =>
                !deletedRows.has(i) && ( // N'affiche pas les lignes supprimées
                  <tr
                    key={i}
                    className="hover:bg-gray-100 transition duration-200"
                  >
                    <td className="px-4 py-2">
                      <div className="relative flex items-center group">
                        {/* Titre réduit avec une largeur maximale */}
                        <div
                          className="text-sm font-medium text-gray-800 truncate"
                          style={{ maxWidth: '125px' }}
                        >
                          {item.imageName}
                        </div>

                        {/* Tooltip affiché au survol */}
                        <div
                          className="absolute top-full left -ml-4 -translate-x-1/2 mt-2 opacity-0 group-hover:opacity-100 scale-95 group-hover:scale-100 bg-gradient-to-r from-viguierBleu via-blue-900 to-viguierBleuClair text-white text-xs font-semibold px-2 py-2 rounded-md shadow-2xl z-10 transition-all duration-300"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {item.imageName}
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="flex items-center space-x-4">
                        {item.imageUrl && (
                          <div className="relative group">
                            <img
                              src={item.imageUrl}
                              alt={item.imageName}
                              className="w-20 h-20 object-cover rounded-md border border-gray-300 shadow-md"
                            />
                            <div
                              className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
                              onClick={() =>
                                window.open(item.imageUrl, '_blank')
                              }
                            >
                              <VisibilityIcon className="text-white w-8 h-8 cursor-pointer" />
                            </div>
                          </div>
                        )}
                        <button
                          className="p-2 rounded-full hover:bg-red-200"
                          onClick={() => deleteRow(i, event)}
                        >
                          <DeleteIcon className="text-red-600" />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
            )}
          </tbody>
          <tfoot>
            {!isSimple ? (
              <tr>
                {Object.keys(format).map((field, i) => {
                  return (
                    <td key={i}>
                      <input
                        type={format[field].type || 'text'}
                        className="w-full overflow-ellipsis overflow-x-hidden text-sm py-1 px-1"
                        placeholder="Ajouter un champ..."
                        value={newTextValue[i]}
                        onChange={(e) =>
                          setNewTextValue(
                            newTextValue.map((t, i2) => {
                              return i2 === i ? e.target.value : t;
                            })
                          )
                        }
                      />
                    </td>
                  );
                })}
                <td>
                  <div className="flex border-l-2 border-black justify-center items-center">
                    <AddIcon className="cursor-pointer" onClick={addRow} />
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={type !== 'image' ? 1 : 2}>
                  <input
                    className="w-full overflow-ellipsis overflow-x-hidden text-sm py-1 px-2"
                    placeholder={'Ajouter un champ...'}
                    type={type === 'image' ? 'file' : 'text'}
                    value={newTextValue[0]}
                    onChange={async (e) => {
                      if (type === 'image') {
                        const file = e.target.files[0];
                        if (!file || !file.type.startsWith('image/')) {
                          return setError(
                            'Le fichier doit être une image valide'
                          );
                        }
                        setError('');
                        setInfo("Compression de l'image en cours...");
                        try {
                          const compressedFile = await compressImage(file);
                          setInfo("Téléchargement de l'image en cours...");
                          const psu = await getPutPSU(
                            state.token,
                            `${numFiche}_${file.name}`
                          );
                          if (psu.error) {
                            setInfo('');
                            return setError(`Erreur : ${psu.error}`);
                          }
                          const upload = await putMinio(
                            psu.url,
                            compressedFile,
                            file.type
                          );
                          if (upload.error) {
                            setInfo('');
                            return setError(`Erreur : ${upload.error}`);
                          }
                          setInfo('Image téléchargée avec succès !');
                          setter([...values, psu.name]);
                        } catch (error) {
                          console.error(error);
                          setInfo('');
                          return setError(`Impossible d'utiliser la photo`);
                        }
                      } else {
                        setNewTextValue([e.target.value]);
                      }
                    }}
                    list={`datalist-array-${id}`}
                  />
                  {options && (
                    <datalist id={`datalist-array-${id}`}>
                      {options.map((o) => (
                        <option value={o.label} key={o.value} />
                      ))}
                    </datalist>
                  )}
                </td>
                {type !== 'image' && (
                  <td>
                    <div className="border-l-2 border-black w-full flex justify-center items-center">
                      <AddIcon className="cursor-pointer" onClick={addRow} />
                    </div>
                  </td>
                )}
              </tr>
            )}
          </tfoot>
        </table>
      </div>
    </div>
  );
}
