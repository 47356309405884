import React, { useContext, useEffect, useState } from 'react';

import Message from '../components/Message';
import { Context } from '../components/Store';
import { enable2FA, login, verify2FA } from '../functions/user';

export default function Login() {
  const [, setState] = useContext(Context);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [userId, setUserId] = useState(null);
  const [rememberDevice, setRememberDevice] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [secret, setSecret] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState('login');
  const [useQRCode, setUseQRCode] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('2fa_token');
    if (storedToken) {
      setStep('login');
    }
  }, []);

  const log = async () => {
    setIsLoading(true);
    document.getElementById('submitBtn').disabled = true;

    // ✅ Récupérer le token spécifique à l'utilisateur (évite les conflits entre utilisateurs)
    const stored2faToken = localStorage.getItem(`2fa_token_${username}`);
    console.log('Stored 2FA token:', stored2faToken);
    console.log('Username:', username);

    const response = await login({
      username,
      password,
      twoFaToken: stored2faToken, // ✅ Envoi du bon token
    });

    setIsLoading(false);
    document.getElementById('submitBtn').disabled = false;

    if (response.error) {
      setError(response.error);
      setPassword('');
    } else if (response.require2FA) {
      setUserId(response.userId);
      localStorage.setItem('temp-token', response['temp-token']);
      setStep('2fa');
      fetchQrCode(response['temp-token']); // ✅ Affichage du QR Code
    } else {
      // 🔹 Connexion réussie (sans 2FA)
      localStorage.setItem('user', JSON.stringify(response.user));
      localStorage.setItem('token', response['auth-token']);
      setState({ ...response, token: response['auth-token'] });
    }
  };

  const fetchQrCode = async (tempToken, forceRegenerate = false) => {
    setIsLoading(true);
    const response = await enable2FA(tempToken, forceRegenerate);
    setIsLoading(false);

    if (response.error) {
      setError(response.error);
    } else {
      setQrCode(response.qrCode);
      setSecret(response.secret);
    }
  };

  const handleVerify2FA = async () => {
    if (!twoFactorCode) return setError('Veuillez entrer le code 2FA.');

    setIsLoading(true);
    const tempToken = localStorage.getItem('temp-token'); // Récupération du temp-token
    const response = await verify2FA({
      userId,
      token: twoFactorCode,
      rememberDevice,
    });

    setIsLoading(false);

    if (response.error) {
      setError(response.error);
      setTwoFactorCode('');
    } else {
      if (response['2fa_token'] && rememberDevice) {
        // ✅ Stocker le token avec l'ID utilisateur pour éviter d'écraser celui des autres
        localStorage.setItem(`2fa_token_${username}`, response['2fa_token']);
      }

      // 🔹 Nettoyage du temp-token
      localStorage.removeItem('temp-token');

      // 🔹 Sauvegarde des informations utilisateur
      localStorage.setItem('user', JSON.stringify(response.user));
      localStorage.setItem('token', response['auth-token']);
      setState({ ...response, token: response['auth-token'] });
    }
  };

  document.title = 'VIGUIER - Login';

  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-2 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg w-full space-y-8">
          <div>
            <img src="/Logo viguier.png" alt="Viguier" />
            <h2 className="mt-6 text-center text-2xl md:text-3xl font-extrabold text-gray-900">
              {step === 'login'
                ? 'Connectez-vous à votre compte'
                : 'Vérification 2FA'}
            </h2>
          </div>

          {step === 'login' ? (
            <form
              className="mt-8 space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                log();
              }}
            >
              <div className="rounded-md shadow-sm -space-y-px">
                <input
                  type="text"
                  placeholder="Nom d'utilisateur"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="block w-full px-3 py-2 border rounded-t-md"
                  required
                />
                <input
                  type="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full px-3 py-2 border rounded-b-md"
                  required
                />
              </div>
              {error && <Message message={error} setError={setError} />}
              <button
                id="submitBtn"
                type="submit"
                className="w-full bg-viguierBleu text-white py-2 rounded"
              >
                {isLoading ? 'Chargement...' : 'Connexion'}
              </button>
            </form>
          ) : (
            <div className="mt-8 space-y-6">
              <p className="text-center text-gray-700">
                Choisissez une méthode pour entrer le code 2FA :
              </p>

              <div className="flex justify-center space-x-4">
                <button
                  className={`py-2 px-4 ${
                    useQRCode
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-300 text-gray-700'
                  }`}
                  onClick={() => setUseQRCode(true)}
                >
                  Scanner QR Code
                </button>
                <button
                  className={`py-2 px-4 ${
                    !useQRCode
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-300 text-gray-700'
                  }`}
                  onClick={() => setUseQRCode(false)}
                >
                  Entrer la clé secrète
                </button>
              </div>

              {useQRCode ? (
                <>
                  {qrCode ? (
                    <>
                      <p className="text-center text-gray-800 font-semibold text-lg md:text-xl">
                        Scannez ce QR Code avec votre application 2FA et entrez
                        le code généré :
                      </p>
                      <div className="flex justify-center mt-6">
                        <img
                          src={qrCode}
                          alt="QR Code 2FA"
                          className="border border-gray-300 rounded-lg shadow-lg p-4"
                        />
                      </div>
                    </>
                  ) : (
                    <p className="text-gray-700 text-center">
                      Le 2FA est déjà activé pour votre compte.
                    </p>
                  )}
                  <button
                    className=""
                    onClick={() =>
                      fetchQrCode(localStorage.getItem('temp-token'), true)
                    }
                  ></button>

                  {qrCode && (
                    <div className="text-center text-sm text-gray-600 mt-4 space-y-4 md:space-y-5 px-4 md:px-6">
                      <p>
                        1. Si vous n'avez pas encore d'application
                        d'authentification, vous pouvez en télécharger une
                        depuis votre magasin d'applications, par exemple :
                      </p>
                      <ul className="list-disc text-gray-700 pl-5 space-y-1">
                        <li>Google Authenticator (Android / iOS)</li>
                        <li>Authy (Android / iOS)</li>
                        <li>Microsoft Authenticator (Android / iOS)</li>
                      </ul>
                      <p>
                        2. Scannez ce QR Code directement avec votre appareil
                        photo ou l'application d'authentification.
                      </p>
                      <p>
                        3. Le code sera généré automatiquement dans
                        l'application.
                      </p>
                      <p>
                        4. Entrez ensuite le code généré dans le champ
                        ci-dessous.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {secret ? (
                    <div className="text-center text-sm text-gray-600 mt-4 space-y-4 md:space-y-5 px-4 md:px-6">
                      <p>
                        1. Si vous n'avez pas encore d'application
                        d'authentification, vous pouvez en télécharger une
                        depuis votre magasin d'applications, par exemple :
                      </p>
                      <ul className="list-disc text-gray-700 pl-5 space-y-1">
                        <li>Google Authenticator (Android / iOS)</li>
                        <li>Authy (Android / iOS)</li>
                        <li>Microsoft Authenticator (Android / iOS)</li>
                      </ul>
                      <p>
                        2. Ouvrez ensuite votre application d'authentification.
                      </p>
                      <p>
                        3. Entrez la clé secrète ci-dessous dans l'application
                        pour générer un code :
                      </p>
                      <p>
                        <strong className="text-gray-900 font-semibold">
                          {secret}
                        </strong>
                      </p>
                      <p>
                        4. Entrez le code généré par l'application dans le champ
                        ci-dessous.
                      </p>
                    </div>
                  ) : (
                    <p className="text-center text-gray-700 ">
                      Le 2FA est déjà activé pour votre compte.
                      <button
                        className=""
                        onClick={() =>
                          fetchQrCode(localStorage.getItem('temp-token'), true)
                        }
                      ></button>
                    </p>
                  )}
                </>
              )}

              <input
                type="text"
                maxLength="6"
                placeholder="••••••"
                value={twoFactorCode}
                onChange={(e) => setTwoFactorCode(e.target.value)}
                className="block w-full px-3 py-2 border text-center"
                required
              />

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberDevice"
                  checked={rememberDevice}
                  onChange={() => setRememberDevice(!rememberDevice)}
                  className="mr-2"
                />
                <label
                  htmlFor="rememberDevice"
                  className="text-sm text-gray-700"
                >
                  Se souvenir de cet appareil
                </label>
              </div>
              <p className="text-xs text-gray-500 mt-2">
                En cochant cette option, vous serez reconnu automatiquement sur
                cet appareil pendant 7 jours.
              </p>

              {error && <Message message={error} setError={setError} />}
              <button
                onClick={handleVerify2FA}
                className="w-full bg-viguierBleu text-white py-2 rounded"
              >
                {isLoading ? 'Vérification...' : 'Valider le code'}
              </button>

              <button
                onClick={() => setStep('login')}
                className="text-sm text-viguierBleu hover:underline"
              >
                Retour à la connexion
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
